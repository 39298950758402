.article-plan {
  padding: 10px 30px 45px 30px;

  .title {
    text-transform: none;
    border: none;
    margin-bottom: 15px;
    display: inline-block;
    padding: 0;
  }

  .detail-box {
    img {
      margin: 20px 0;
    }

    .article-small-img {
      float: left;
      margin: 20px 20px 20px 0;
    }
  }
}
.article-box {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  display: -ms-flexbox;
  -ms-flex-pack: justify;
  margin-bottom: 30px;

  .article-img {
    width: 325px;
    height: 181px;
    margin-right: 35px;

    img {
      width: 325px;
      display: block;
    }
  }
  .article-text {
    max-width: 465px;

    .title {
      border-bottom: none;
      text-transform: none;
      padding-bottom:0;
    }

    p {
      font-size: 14px;
      color: #576272;
    }
  }
}

.company-content {
  .title {
    margin-bottom: 0;
  }
}
.main-content {
  .company-detail {
    padding: 0;
    border-bottom: 2px solid #404550;

    .company-detail-body {
      padding: 60px 20px;
      font-size: 14px;

      .title {
        border: none;
        text-transform: none;
      }
    }

    .company-contact {
      display: flex;
      display: -webkit-flex;
      display: -ms-flexbox;
      margin-bottom: 40px;

      p {
        margin-bottom: 8px;
      }

      div {
        margin-right: 30px;
      }

      .logo {
        max-width: 200px;

        img {
          max-width: 100%;
        }
      }

      .contact {
        min-width: 249px;


        a {
          display: inline-block;
          color: #00add9;
        }

        .button {
          border-radius: 3px;
          background-color: #00add9;
          color: #fff;
          padding: 6px;
          width: 100%;
          outline: none;
          max-width: 245px;
          text-align: center;
          text-decoration: none;
        }
      }
    }

    .comp-wrap {
      margin-bottom: 50px;

      p {
        margin-bottom: 15px;
      }

      h4 {
        border-bottom: 2px solid #ebebeb;
        margin-bottom: 18px;
        font-size: 20px;
        padding-bottom: 18px;
      }
    }

    .go-back {
      padding-top: 50px;
      font-size: 14px;
      font-weight: 700;
    }

    .text-center {
      margin-bottom: 15px;
    }
  }
}

//default form styles
input,
textarea {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #a0a0a0;
  background-color: #fff;
  border-radius: 4px;
  font-weight: 300;
  padding: 12px 24px;
  outline: 0;

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #576272;
    font-size: 16px;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: #576272;
    font-size: 16px;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: #576272;
    font-size: 16px;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: #576272;
    font-size: 16px;
  }
}

form {

  .character-count {
    text-align: right;
    font-size: 14px;
    display: block;
    font-weight: 300;
    color: #576272;
    margin-bottom: 15px;
  }

  textarea {
    margin-top: 20px;
  }

  label {
    font-weight: normal;
    font-size: 18px;
  }

  .radio-check {
    display: inline-block;
    float: right;

    input[type="radio"]{
      display: none;
    }

    input[type="radio"]:checked + label:after {
      content: "";
      background-color: #00add9;
      text-align: center;
      line-height: 18px;
    }

    label {
      cursor: pointer;
      font-size: 16px;
      font-weight: 300;
      margin-left: 10px;

      &:after {
        content: "";
        display: inline-block;
        border-radius: 8px;
        position: relative;
        top: 2px;

        width: 11px;
        height: 11px;

        margin-left: 5px;
        background-color: transparent;
        border: 3px solid #596474;
      }
    }
  }
}

//auth style

.form-plan {
  padding: 70px 13%;

  &.no-border {
    border: none;
  }

  p {
    text-align: center;
    font-size: 14px;
  }

  .form-group {
    margin-bottom: 40px;
    @include clearfix;

    .title {
      text-align: left;
      text-transform: none;
      padding-bottom: 10px;
      margin-top: 30px;
      border-bottom: 1px solid #ebebeb;
    }

    &.captcha-wrap {
      label {
        display: block;
      }
      input,
      img {
        display: inline-block;
        vertical-align: middle;
      }
      img {
        margin-left: 36px;
      }
    }

    &.validation-error {
      input {
        border: 1px solid #e4065a;

        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          color: #e4065a;
        }
        &::-moz-placeholder { /* Firefox 19+ */
          color: #e4065a;
        }
        &:-ms-input-placeholder { /* IE 10+ */
          color: #e4065a;
        }
        &:-moz-placeholder { /* Firefox 18- */
          color: #e4065a;
        }
      }
    }
  }

  .auth-btn {
    margin-top: 56px;
  }

  .auth-btn:hover {
    color: #fff;
  }

  .recovery {
    font-size: 14px;
    font-weight: 700;
    display: inline-block;
    float: right;
    margin-top: 5px;
  }
}

.modal {
  .modal-header {
    border: none;
    padding: 30px 5% 0 5%;

    .close {
      font-size: 31px;
    }
  }
}

.modal-form {
  padding: 5% 10%;

  .form-group {
    margin-bottom: 20px;
  }
  form {
    label,
    input,
    textarea {
      font-size: 14px;
      resize: none;
      margin-top: 0;

      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #576272;
        font-size: 14px;
      }
      &::-moz-placeholder { /* Firefox 19+ */
        color: #576272;
        font-size: 14px;
      }
      &:-ms-input-placeholder { /* IE 10+ */
        color: #576272;
        font-size: 14px;
      }
      &:-moz-placeholder { /* Firefox 18- */
        color: #576272;
        font-size: 14px;
      }
    }
  }

}
.bootstrap-select {
  width: 100% !important;
  position: relative;

  button {
    max-width: 100%;
    box-sizing: border-box;
    border: 1px solid #a0a0a0;
    background-color: #fff;
    border-radius: 4px;
    font-weight: 300;
    padding: 15px 26px 12px 26px;
    outline: 0;
    margin-bottom: 0;
    position: relative;
    line-height: 14px;


    span {
      color: #576272;
      font-size: 14px;
      line-height: 14px;
    }
  }
}

.offer-form {
  p {
    color: #576272;
    font-size:14px;
    font-weight: 300;
    text-align: left;
  }

  .title {
    margin-bottom: 45px;
  }
}
.remember-me {
  text-align: right;
  margin: 10px 0;

  label {
    font-size: 14px;
  }
  input {
    width: auto;
  }
}
.message-failed {
  color: #e4065a;
  p {
    font-size: 16px;
  }
}
.message-success {
  color: #008000;

  p {
    font-size: 16px;
  }
}

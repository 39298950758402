@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

//variables

$blue : #00add9;
$grey : #404550;
$red : #e4065a;


//bootstrap
@import "bootstrap";
@import "mixins";


//pages
@import "general";
@import "icons";
@import "header";
@import "search";
@import "content";
@import "footer";
@import "events";
@import "articles";
@import "form";
@import "jobs";
@import "services";
@import "content";
@import "company";
@import "jquery-ui";
@import "bootstrap-select";
@import "flexnav";
@import "edit";
@import "legislation";
@import "abonamente";

@import "mobile";

.icons {
  background-repeat: no-repeat;
  background-position: 0 0;
  display: inline-block;
  position: relative;
}

.article-icon {
  @extend .icons;

  width: 23px;
  height: 29px;
  background-image: url("../images/icons/articles.svg");
  background-position: 0 0;
  background-size: 23px 58px;
}
.events-icon {
  @extend .icons;

  width: 25px;
  height: 29px;
  background-position: 0 0;
  background-size: 25px 58px;
  background-image: url("../images/icons/events.svg");
}
.jobs-icon {
  @extend .icons;

  width: 20px;
  height: 29px;
  background-position: 0 0;
  background-size: 20px 58px;
  background-image: url("../images/icons/jobs.svg");
}
.auth-icon {
  @extend .icons;

  background-image: url("../images/icons/login.svg");
  width: 20px;
  height: 29px;
  background-position: 0 0;
  background-size: 20px 58px;
}
//footer

.footer-mail {
  @extend .icons;

  width: 25px;
  height: 15px;
  background-image: url("../images/icons/mail-icon.svg");
}
.footer-linkedin {
  @extend .icons;

  width: 25px;
  height: 25px;
  background-image: url("../images/icons/linkedin.svg");
  background-position: 0 0;
  background-size: 25px 50px;
}
.footer-facebook {
  @extend .icons;

  width: 25px;
  height: 25px;
  background-image: url("../images/icons/facebook.svg");
  background-position: 0 0;
  background-size: 25px 50px;
}

//share icons

.facebook {
  @extend .icons;

  background-image: url("../images/icons/share-fb.svg");
  width: 41px;
  height: 41px;
  background-position: 0 0;
  background-size: 41px 82px;
}

.twitter {
  @extend .icons;

  background-image: url("../images/icons/share-tw.svg");
  width: 41px;
  height: 41px;
  background-position: 0 0;
  background-size: 41px 82px;
}

//home
.home-icon {
  @extend .icons;

  background-image: url("../images/icons/acasa.svg");
  width: 36px;
  height: 36px;
  background-position: 50% 50%;
  background-size: 36px 36px;
}

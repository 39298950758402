/* MEDIA QUERY UNDER 1360px */

@include media-query(screen1360) {
  .wrap {
    width: 100%;
  }

  .search {
    .search_opt {
      right: 80px;
    }
  }

  /* ------ HEADER ------ */

  header {
    padding: 0 20px;

    .commercial {
      padding: 10px;

      img {
        max-width: 100%;
      }
    }
    .top-nav {

      .menu li a {
        font-size: 12px;
        padding: 9px;
      }
    }
  }
  nav {
    position: relative;

    .mobile {
      padding-left: 0;
      ul {
        padding-left: 0;
      }
    }

    .main-menu {
      justify-content: space-around;
      -webkit-justify-content: space-around;


      .menu-link {
        font-size: 14px;
      }

      .dropdown-menu {
        font-size: 12px;
        top: 80px;
      }

      .last-drop {
        left: 29%;
      }
    }
  }


  /* ------ CONTENT ------ */

  .content {
    padding: 40px 20px 0 20px;
  }

  /* ------ COMMERCIAL ------ */

  .main-content {
    .commercial {
      padding: 40px 0;

      img {
        max-width: 100%;
      }
    }
  }

  /* ------ NEWS ------ */
  .news {
    padding: 40px 0 20px 0;

    .carousel .news-wrap .news-img {
      width: 200px;
      height: 120px;
    }
    .carousel .news-wrap .news-text {
      p, a {
        font-size: 12px;
      }
    }
  }

  /* ------ NEWS ------ */
  footer {
    wrap {
      width: 100%;
    }
  }

  /* ------------ ARTICLES PAGE ------------ */

  .article-box {
    .article-img {
      img {
        max-width: 250px;
      }
    }
  }

  /* ------ ARTICLE DETAIL ------ */

  .article-plan .detail-box img {
    max-width: 100%;
  }
}

/* MEDIA QUERY UNDER 1300 PX */

@include media-query(screen1300) {

  /* ------ SEARCH ------ */

  .search {
    .search_opt {
      display: none;
    }
  }


  header {
    .top-nav {
      flex-direction: column;
      -webkit-flex-direction: column;
    }
  }

  /* ------ REGISTER ------ */

  .main-content {
    .register {
      //padding: 40px 15%;
      display: none;
    }
  }

}

/* MEDIA QUERY UNDER 1024 PX */

@include media-query(screen1024) {

  nav {
    .mobile {
      display: block;
    }

    .navbar {
      margin: 0;
      .navbar-header {
        display: block;

        .navbar-brand {
          padding: 15px 15px;
          height: auto;
          color: #fff;
          text-decoration: none;
        }

        .navbar-toggle {
          background-color: #fff;
          border-radius: 2px;

          span {
            background-color: $grey;
          }
        }
      }
      .navbar-collapse {
        overflow: hidden;
      }
    }

    .main-menu {
      display: block;
      padding: 10px 15px;

      .menu-link {
        font-size: 16px;
        padding: 10px 0;

      }

      .dropdown {
        outline: none;

        &.open {
         .menu-link:before {
           transform: rotate(0deg);
         }
        }
      }

      .dropdown-menu {
        left: 0;
        transform: none;

        a {
          display: block;

          span {
            float: right;
            margin-left: auto;
          }
        }
      }
    }
  }

  .header-image {
    .text-holder {
      width: 100%;
    }
  }

  .search form {
    width: 100%;
    padding: 0 40px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .side-filter {
    display: none;
  }
  .sidebar {
    width: 20%;

    .side-commercial {

      img {
        max-width: 100%;
      }
    }
  }

  /* ------ MAIN CONTENT ------ */

  .main-content {
    width: 100%;

    .commercial {
      max-width: 100%;
    }

    .legislation {
      .col-sm-6 {
        padding-right: 0;
      }
    }

  }

  .sidebar {
    width: 100%;
    padding: 0 20px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin-top: 30px;

    .side-partners {
      border-bottom: 2px solid #404550;
      margin-bottom: 30px;
    }

    .side-legislation ul li {
      justify-content: flex-start;
      -webkit-justify-content: flex-start;
      margin-bottom: 15px;
    }
  }

  /* ------ FOOTER ------ */

  footer {
    .wrap {
      flex-direction: column;
      -webkit-flex-direction: column;
    }
    .footer-menu {
      width: 100%;
    }
    .about {
      width: 100%;

      p {
        margin-bottom: 15px;
      }
    }
  }


  .news {
    display: none;
  }
}

/* MEDIA QUERY UNDER 800px */

@include media-query(screen800) {
  .commercial {
    display: none !important;
  }
  .side-commercial {
    display: none !important;
  }

  .menu {
    li:nth-child(3) {
      display: none;
    }
  }


  .article-box {
    flex-direction: column;
    -webkit-flex-direction: column;
    text-align: center;
    align-items: center;
    -webkit-align-items: center;

    .article-img {
      width: 100%;
      margin-right: 0;

      img {
        margin: 0 auto;
        max-width: 100%;
      }
    }
  }

  /* ------ PAGINATION ------ */

  .pagination {
    li {
      padding: 0 5px;
    }
  }

  /* ------ TAGS ------ */

  .main-content {
    .tags {
      text-align: center;
    }


    /* --- COMPANY DETAIL --- */

    .company-detail {
      .company-contact {
        flex-direction: column;
        align-items: center;
        text-align: center;
        -webkit-flex-direction: column;
        -webkit-align-items: center;

        .logo,
        .address,
        .contact {
          margin-right: 0;
          margin-bottom: 20px;

          .button {
            max-width: 100%;
          }
        }
      }
      .company-presentation {
        text-align: center;

        img {
          max-width: 100%;
        }
      }
    }
  }


}

/* MEDIA QUERY UNDER 600px */

@include media-query(screen600) {
  header {
    padding: 0;
  }

  .search {
    form {
      input {
        background-image: none;
      }
    }
  }

  .btn {
    max-width: 90%;
    font-size: 16px;
  }

  /* ------ HEADER IMAGE ------ */
  .header-image h2 {
    font-size: 24px;
    padding: 0 20px;
  }

  /* ------ CONTENT ------ */
  .content {
    padding: 40px 0;

    .title {
      //padding-left: 20px;
      text-align: center;
    }

    .description {
      padding: 20px 10%;
      text-align: center;

      p {
        font-size: 14px;

        &.big {
          font-size: 16px;
        }
      }
    }
  }

  .main-content {
    padding: 0;

    .announce {
      padding: 30px 0 40px 0;
      text-align: center;

      .announce-box {
        padding: 0 20px 20px 20px;
      }

      &.services {
        padding: 20px 0;

        .announce-box {
          flex-direction: column;
          -webkit-flex-direction: column;

          .logo {
            margin-right: 0;
            margin-bottom: 20px;
          }
        }

        .tags {
          padding-bottom: 25px;

          .tag {
            margin-bottom: 15px;
          }
        }
      }
    }

    .register {
      padding: 20px 10%;

      ul {
        p {text-align: left;}

        li:before {
          width: 42px;
        }
      }

      p {
        font-size: 12px;
        text-align: center;
      }

      h4 {
        font-size: 16px;
        text-align: center;
        line-height: 20px;
      }
    }

    .legislation {
      .legislation-detail-list {
        text-align: center;
      }

      .legislation-list {
        text-align: center;

        li {
          a {
            padding-left: 0;
          }
        }
      }
    }
  }

  /* ------ SIDEBAR ------ */
  .sidebar {
    padding: 0;

    .side-legislation {
      padding: 0 20px;
    }
  }

  /* ------ FOOTER ------ */

  footer {
    text-align: center;
    padding: 40px 20px 40px 20px;

    .footer-menu {
      flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
      justify-content: center;
      -webkit-justify-content: center;
      text-align: left;

      div {
        margin-bottom: 20px;
        padding: 0 10px;

        &.contact {
          text-align: center;
        }

        h5 {
          margin-bottom: 5px;
        }
      }
    }

    .contact ul li {
      justify-content: center;
      -webkit-justify-content: center;
    }
  }

  /* ------------ EVENTS PAGE ------------ */
  .event-plan {
    padding: 20px;
    .event-box {
      flex-direction: column;
      -webkit-flex-direction: column;
      align-items: center;
      -webkit-align-items: center;
      text-align: center;

      .date {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }

  .event-detail {
    .detail-box {
      text-align: center;

      img {
        width: 100%;
      }
    }
    .social-box {
      flex-direction: column;
      -webkit-flex-direction: column;
    }
  }
}

.event-plan {
  padding: 18px 50px 40px 50px;
  border-bottom: 2px solid #404550;

  .event-box {
    display: flex;
    display: -webkit-flex;
    margin-bottom: 50px;
    display: -ms-flexbox;

    .date {
      background-image: url("../images/icons/events-calendar.svg");
      background-repeat: no-repeat;
      width: 115px;
      height: 121px;
      margin-right: 45px;
      text-align: center;

      span {
        display: block;
      }

      .month {
        padding: 8px 0;
        font-size: 18px;
        color: #fff;
      }
      .day {
        font-size: 46px;
        color: #00add9;
        line-height: 48px;
        padding-top: 3px;
      }
      .year {
        font-size: 14px;
        color: #576272;
      }
    }

    .event-info {
      font-size: 14px;
      max-width: 625px;

      .event-title {
        font-size: 22px;
        color: #00add9;
        margin-bottom: 20px;
      }
      .bold {
        font-weight: 700;
      }

      a {
        display: inline-block;
        font-weight: 700;
        margin-top: 5px;
        color: #00add9;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

//detail page

.event-detail {
  .event-box {
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 30px;
    margin-bottom: 0;

    .event-info {
      a {
        color: #576272;
      }
    }
  }

  .detail-box {
    color: #576272;
    font-size:14px;
    padding: 30px 0;
    border-bottom: 1px solid #ebebeb;

    p:last-child {
      margin-bottom: 0;
    }
  }

  .event-social {
    margin: 0 0 0 10px;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;
      margin-right: 11px;

      img {
        width: 41px;
      }

      a:hover {
        background-position: 0 -41px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .social-box {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    display: -ms-flexbox;
    -ms-flex-align: center;
  }

  .detail-back {
    font-weight: 700;
  }

  .last-box {
    padding-bottom: 0;
    border: none;

    span {
      font-weight: 700;
      margin-right: 5px;
    }
  }
}

// EVENT PAGE SETTINGS

.settings-plan {
  padding: 70px 13%;

  .offer-info {
    margin-bottom: 50px;
  }

  p {
    text-align: left;
  }

  .subtitle {
    font-size: 20px;
    text-align: left;
    color: #404550;
    margin-bottom: 30px;

    &.blue {
     color: $blue;
    }
  }

  .auth-btn {
    margin-top: 56px;
  }
}

.event-title,
.offer-title {
  margin-bottom: 10px;
}
.event-list,
.offer-list {
  border: 1px solid #a0a0a0;
  background-color: #fff;
  border-radius: 4px;
  font-weight: 300;
  font-size: 14px;

  .col-sm-6 {
    padding-left: 26px;
  }

  .list {
    padding-bottom: 10px;
    margin: 12px;
    border-bottom: 1px solid #a0a0a0;

    .col-sm-6 {
      left: 0;
    }

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
    }

    .delete {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
    .modify {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    .last-col {
      padding-left: 12px;
    }
  }
}
.settings-plan {
  .col-sm-3 {
    text-align: center;
  }
}

//offers modify

.offer-title {
  .last-col {
    float: right;
    text-align: center;
  }
}
.offer-list {
  .last-col {
    float: right;
    text-align: center;
  }
}

body {
  font-family: 'Montserrat', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
}

.wrap {
  width: 1360px;
  margin: 0 auto;
}

.section-title {
  color: #00add9;
  text-align: center;
  font-size: 22px;
  margin-top: 0;
  margin-bottom: 30px;
  text-transform: uppercase;
}

//pagination

.pagination {
  text-align: center;
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    display: inline-block;
    padding: 0 25px;

    a {
      text-decoration: none;

      &:hover,
      &.active {
        text-decoration: underline;
      }
    }
  }
}

//buttons

.btn {
  width: 100%;
  background-color: #1fcfcb;
  border-radius: 4px;
  font-size: 18px;
  outline: none;
  color: #fff !important;
  text-decoration: none;
  text-align: center;
  padding: 18px 0;
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
  max-width: 440px;
}
.btn:active,
.btn:focus {
  outline: none;
}

.add-btn {
  max-width: 174px;
  padding: 13px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #00add9;
  margin-right: 0;
  margin-top: 30px;
}
.delete-btn {
  max-width: 174px;
  padding: 13px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #ec4b88;
  margin-right: 0;
  margin-top: 30px;
}

.edit-btn {
  padding: 8px;
  max-width: 154px;
  font-size: 14px;
  margin: 0;
  margin-left: 15px;
}

.col-sm-6,
.col-sm-4,
.col-sm-2,
.col-sm-3 {
  padding-left: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.col-sm-6:last-child {
  position: relative;
  //left: 15px;

  @media only screen and (max-width: 1022px) {
    left: 0;
  }
}

.last-col {
  padding-right:0;
}

.modal-form {
  .title {
    margin: 0;
    font-size: 22px;
    color: #00add9;
  }
  .subtitle {
    color: #576272;
    text-align: left;
    margin-bottom:50px;
  }
}

//tabs

.tabs {
  background-color: #f3f8fa;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;

      &.active,
      &:hover {
        border-bottom: 2px solid #404550;
      }

      a {
        font-size: 20px;
        padding: 10px 20px;
        display: block;
        text-decoration: none;
      }
    }
  }
}

//colors

.modify {
  color: #1fcfcb !important;
}
.delete {
  color: $red !important;
}

//upload

.image-upload {
  input {
    display: none;
  }
  label {
    display: block;

    &.image-output {
      margin: 10px auto;
      width: 51px;

      img {
        max-width: 100%;
        cursor: pointer;
      }
    }
  }
}

//image capitol

.image-left {
  float: left;
  margin: 20px 20px 20px 0;
}
.image-right {
  float: right;
  margin: 20px 0 20px 20px;
}
.image-center {
  text-align: center;
  margin: 20px 0;
}

//template articles

.template-article {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    width: 48%;
    margin-right: 2%;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-bottom: 30px;

    &:nth-child(2n) {
      margin-right: 0;
    }

    &:hover {
      border: 1px solid #000;
    }
  }

  img {
    max-width: 100%;
  }
}

.sticker {
  position: fixed;
  right: -10px;
  top: 50%;
  margin-top: -20px;

  a {
    font-size: 16px;
    color: #fff;
    font-weight: 700;
    display: block;
    background-color: #576272;
    padding: 10px 20px 10px 10px;
    text-decoration: none;

    &:hover {
      background-color: #00add9;
    }
  }
}

.jobs-plan {
  border-bottom: 2px solid #404550;

  .no-job {
    text-align: center;
    padding: 70px 0;

    p {
      font-size: 36px;
      color: #00add9;
    }
  }
}
footer {
  padding: 65px 20px 130px 20px;
  background-color: #404550;

  .wrap {
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    display: -ms-flexbox;
    -ms-flex-pack: justify;
  }
  .about {
    width: 42%;
  }

  h4, h5 {
    font-size: 18px;
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 20px;
  }

  a {
    color: #fff;
    font-size: 14px;

    &:hover {
      text-decoration: none;
    }
  }

  span {
    margin-right: 10px;
  }

  p {
    font-size: 14px;
    color: #fff;
    margin-bottom: 30px;
  }

  .footer-menu {
    width: 50%;
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    display: -ms-flexbox;
    -ms-flex-pack: justify;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }

  .contact ul li {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    margin-bottom: 10px;
    display: -ms-flexbox;
  }
}

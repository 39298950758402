.content {
  padding: 40px 0 0 0;

  .title {
    color: $blue;
    text-transform: uppercase;
    margin: 0;
    font-size: 22px;
    padding-bottom: 30px;
    border-bottom: 2px solid #404550;
    margin-bottom: 17px;
  }

  .wrap {
    @include clearfix;
  }
}

/* ------ LEFT SIDE (MENU) ------ */

.side-filter {
  float: left;
  width: 16%;

  .selected {
    font-weight: 700;
  }

  .filter-list {

    > .is-active {
      ul li:last-child  {
        padding-bottom: 25px;
      }
    }

    li {
      border-bottom: 1px solid #ebebeb;

      a:before {
        background-image: url("../images/icons/menu-arrow-right.png");
        background-repeat: no-repeat;
        content: '';
        display: inline-block;
        width: 7px;
        height: 14px;
        margin-right: 15px;
        position: relative;
      }

      ul {
        display: none;
        margin-left: 14px;

        a {
          padding: 7px 0;
        }

        li {
          border-bottom: none;
        }
      }

      &.is-active {

        > a {
          padding-bottom: 15px;
        }

        a:before {
          background-image: url("../images/icons/menu-arrow-down.png");
          background-repeat: no-repeat;
          content: '';
          display: inline-block;
          width: 14px;
          height: 7px;
          margin-right: 8px;
        }

        > ul {
          display: block;

          .is-active {

            > a:before {
              background-image: url("../images/icons/menu-arrow-down.png");
              width: 14px;
              height: 7px;
            }

            ul li:last-child {
              padding-bottom: 0;
            }
          }

          .is-active {
            ul {
              a:before {
                background-image: url("../images/icons/menu-arrow-right-dark.png");
                width: 7px;
                height: 14px;
                position: relative;
              }
            }
          }

          a:before {
            background-image: url("../images/icons/menu-arrow-right.png");
            width: 7px;
            height: 14px;
            position: relative;
          }
        }
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;


    a {
      font-size: 14px;
      padding: 21px 0;
      color: #576272;
      text-decoration: none;
      display: flex;
      display: -webkit-flex;
      align-items: center;
      -webkit-align-items: center;
      display: -ms-flexbox;
      -ms-flex-align: center;

      span {
        float: right;
        margin-left: auto;
      }

      &:hover {
        color: $blue;
      }
    }
  }
}

/* ------ MAIN CONTENT ------ */

.main-content {
  color: #404550;
  width: 68%;
  float: left;
  box-sizing: border-box;
  padding: 0 20px;

  a {
    color: #404550;

    &:hover {
      text-decoration: none;
    }
  }

  .description {
    padding: 32px 10% 20px 10%;
    border-bottom: 2px solid #404550;

    p {
      line-height: 32px;
      font-size: 18px;
      margin-bottom: 30px;

      &.big {
        font-size: 22px;
        margin-bottom: 10px;
      }
    }
  }

  &.announce,
  .announce {

    .description {
      padding-bottom: 36px;
    }

    .register-btn {
      color: #fff;
    }

    .title {
      margin-bottom: 40px;
    }

    a.title {
      text-transform: none;
      border: none;
      padding: 0;
      display: inline-block;
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 20px;
      font-size: 14px;
      color: #576272;
    }

    .announce-box {
      padding-bottom: 20px;
      margin-bottom: 36px;
      border-bottom: 1px solid #ebebeb;
      font-size: 14px;

      a {
        font-weight: 700;
      }
    }
  }

  .announce {
    padding: 40px 0;
    border-bottom: 2px solid #404550;
  }

  .register {
    background-color: #e5f7fb;
    border-bottom: 2px solid #404550;
    padding: 65px 25%;

    p {
      font-size: 14px;
    }

    h4 {
      margin-bottom: 20px;
    }

    ul {
      margin: 0;
      margin-bottom: 20px;
      padding: 0;
      list-style: none;


      li {
        margin-bottom: 10px;
        max-width: 443px;

        &:before {
          background-image: url("../images/icons/register-icon.svg");
          background-repeat: no-repeat;
          width: 29px;
          height: 18px;
          display: inline-block;
          content: '';
          vertical-align: top;
        }

        p {
          margin: 0;
          width: 92%;
          display: inline-block;
        }
      }
    }

    .register-btn {
      color: #fff;
    }
  }

  .commercial {
    padding: 40px 80px;
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    justify-content: space-around;
    -webkit-justify-content: space-around;
    -ms-flex-pack: justify;
  }

  .announce {
    padding: 40px 0;
    border-bottom: 2px solid #404550;

    .register-btn {
      color: #fff;
    }

    .title {
      margin-bottom: 40px;
    }

    a.title {
      text-transform: none;
      border: none;
      padding: 0;
      display: inline-block;
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 20px;
      color: #576272;
    }

    .announce-box {
      padding-bottom: 20px;
      margin-bottom: 36px;
      border-bottom: 1px solid #ebebeb;
      font-size: 14px;

      a {
        font-weight: 700;
      }
    }
  }
}

/* ------ NEWS SECTION ------ */

.news {
  background-color: #f3f8fa;
  padding: 60px;

  .carousel {
    .carousel-indicators {
      li {
        background-color: #576272;
        border: none;

        &.active {
          background-color: #404550;
          border: none;
        }
      }
    }

    .news-wrap {
      display: flex;
      display: -webkit-flex;
      display: -ms-flexbox;
      justify-content: space-around;
      -webkit-justify-content: space-around;
      -ms-flex-pack: justify;
      padding-bottom: 90px;

      .news-box {
        display: flex;
        display: -webkit-flex;
        display: -ms-flexbox;
        justify-content: space-between;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;

        img {
          display: block;
        }
      }
      .news-text {
        width: 250px;
        margin-left: 20px;

        p {
          font-size: 14px;
          font-weight: 300;
          margin-bottom: 0;
          color: #576272;

          &.news-title {
            font-weight: 700;
            margin-bottom: 15px;
          }
        }
        a {
          font-size: 14px;
          color: #576272;

          &:hover {
            text-decoration: none;
          }
        }
      }
      .news-img {
        width: 260px;
        height: 162px;
        border-radius: 4px;
        overflow: hidden;
      }
    }
  }
}

/* ------ RIGHT SIDEBAR ------ */

.sidebar {
  width: 16%;
  padding-bottom: 40px;
  float: left;

  .side-partners {
    padding: 20px 0 30px 0;
    text-align: center;
    font-size: 12px;

    p {
      margin-top: 20px;
      color: #576272;
    }
    a {
      color: #576272;

      &:hover {
        text-decoration: none;
      }
    }
  }
  .side-legislation {
    margin-top: 30px;
    margin-bottom: 90px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        display: flex;
        display: -webkit-flex;
        display: -ms-flexbox;
        justify-content: space-between;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        align-items: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        margin-bottom: 30px;

        img {
          margin-right: 10px;
        }

        a {
          font-size: 12px;
          color: #576272;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
  .side-commercial {
    text-align: center;
    margin-bottom: 32px;

    img {
      display: block;
      margin: 0 auto;
    }
  }
}


.settings-content {
  width: 84%;

  .settings-plan {
    border-bottom: none;
  }
}

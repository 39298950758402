.search {
  background-color: #f3f8fa;
  padding: 38px 0;

  .wrap {
    position: relative;
  }

  form {
    width: 900px;
    margin: 0 auto;

    input {
      background-image: url("../images/icons/search.svg");
      background-repeat: no-repeat;
      background-position: 97% center;
      background-size: 27px 27px;
    }
  }

  .search_opt {
    position: absolute;
    right: 100px;
    top: 12px;

    a {
      font-size: 14px;
      color: #576272;
      margin-right: 20px;

      &:hover {
        text-decoration: none;
        color: #00add9;
      }
    }
  }
}

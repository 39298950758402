.main-content {
  .legislation {
    padding: 0 35px 70px 35px;
    border-bottom: 2px solid #404550;

    &-list {
      margin: 15px 0 70px 0;
      padding: 0;
      list-style: none;

      li {
        border-bottom: 1px solid #ebebeb;

        a {
          font-size: 20px;
          color: $blue;
          display: inline-block;
          padding: 20px 0 20px 60px;
        }
      }
    }

    &-detail-list {
      list-style: none;
      padding: 0;
      margin: 35px 0 70px 0;

      li {
        margin-bottom: 40px;

        a {
          font-size: 20px;
          color: $blue;
          display: inline-block;
          border-bottom: 1px solid #ebebeb;
          margin-bottom: 20px;
          padding-bottom: 20px;
        }

        p {
          font-size: 14px;
          color: #576272;
          margin: 0;
        }
      }
    }
  }
}
.abonamente {
  width: 100%;
}

.pachete,
.ebanner {
  padding-top: 100px;

  &-wrap {
    width: 1090px;
    margin: 0 auto;
    @include clearfix;
  }
  .col {
    width: 20%;
    float: left;
    box-sizing: border-box;
  }
  .gold-col,
  .silver-col,
  .bronze-col,
  .expert-col {
    .top-row {
      background-color: #f0f0f0;
    }
  }
  .gold-col,
  .silver-col,
  .bronze-col {
    .info-row {
      position: relative;
      top: -10px;
    }
    .button-row {
      position: relative;
      top: -10px;
    }
  }
  .expert-col {
    .top-row {
      border-left: 1px solid #828282;
      box-sizing: border-box;
    }
  }

  .top-row {
    min-height: 68px;
    text-align: center;
    box-sizing: border-box;

    span {
      padding: 22px 0;
      display: inline-block;
      font-size: 18px;
      color: #828282;
    }
  }

  .price-row {
    text-align: center;
    min-height: 183px;
    position: relative;

    &:after {
      content: '';
      width: 0;
      z-index: 50;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      position: absolute;
      bottom: -10px;
      left: 50%;
      margin-left: -10px;
    }

    h3 {
      color: #fff;
      font-size: 26px;
      font-weight: 600;
      margin: 0;
      padding: 21px 0 40px 0;
    }

    p {
      font-size: 18px;
      font-weight: 300;
      color: #fff;
    }
  }
  .info-col {
    .info-row {
      text-align: left;

      ul {
        border-left: none;

        li {
          justify-content: flex-start;
        }
      }
    }
  }
  .gold-col {
    .price-row {
      background-color: #eaa210;

      &:after {
        border-top: 10px solid #eaa210;
      }
    }
    .button-row {
      a {
        background-color: #eaa210;
      }
    }
  }
  .silver-col {
    .price-row {
      background-color: #89d4d2;

      &:after {
        border-top: 10px solid #89d4d2;
      }
    }
    .button-row {
      a {
        background-color: #89d4d2;
      }
    }
  }
  .bronze-col {
    .price-row {
      background-color: #df495c;

      &:after {
        border-top: 10px solid #df495c;
      }
    }

    .button-row {
      a {
        background-color: #df495c;
      }
    }
  }
  .expert-col {
    .price-row {
      background-color: #9fd389;

      &:after {
        border-top: 10px solid #9fd389;
      }
    }
    .info-row {
      ul {
        li {
          min-height: 612px;
        }
      }
    }
    .button-row {
      a {
        background-color: #9fd389;
      }
    }
  }

  .info-row {
    text-align: center;
    min-height: 612px;

    img {
      width: 28px;
      height: 29px;
      display: block;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      border-left: 1px solid #828282;

      li {
        background-color: #f0f0f0;
        min-height: 68px;
        padding: 15px 45px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;

        &:nth-child(2n) {
          background-color: #e5e5e5;
        }

        span {
          font-size: 14px;
          color: #828282;
        }
      }
    }
  }

  .button-row {
    text-align: center;

    a {
      display: block;
      color: #fff;
      font-size: 26px;
      font-weight: 600;
      padding: 16px 0;
      text-decoration: none;

      &:hover {
        background-color: #828282;
      }
    }
  }
}

.ebanner {
  padding-top: 80px;

  .name-row {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 240px;
    background-color: #00add9;

    h3 {
      line-height: 1;
      color: #fff;
      font-weight: 600;
      margin: 0;
    }
  }

  .info-row {
    min-height: 100%;

    ul {
      li {
        padding: 15px 0;
        min-height: 60px;

        span {
          font-size: 13px;
          font-weight: 300;
        }
      }
    }
  }

  .col {
    width: 20%;

    &.month-col {
      width: 12%;
    }
  }

  .no-border {
    .info-row {
      ul {
        border-left: none;
      }
    }
  }

  .text-row {
    ul {
      li {
        padding: 15px 0 15px 23px;
        justify-content: flex-start;
      }
    }
  }
}

.newsletter {
  padding-top: 45px;
  padding-bottom: 45px;

  .ebanner-wrap {
    &:after,
    &:before {
      display: none;
    }

    display: flex;
    justify-content: space-between;
  }

  .col-2 {
    width: 520px;
    @include clearfix;
  }

  .col {
    width: 29%;

    &.large {
      width: 42%;
    }
  }

  .name-row {
    text-align: center;
    min-height: 120px;

    h3 {
      font-size: 16px;
      font-weight: 400;
      margin: 0;
      padding: 0 10px;
    }
  }
}

.jobs-price {
  padding-bottom: 80px;
  padding-top: 0;

  .col-2 {
    width: 100%;
  }

  .col {
    width: 14%;

    &.last-col {
      width: 36%;
    }

    &.large {
      width: 20%;
    }
  }
}

.abonament-btn {
  margin-bottom: 150px;
}

.main-content .services {
  .tags {
    padding-bottom: 40px;
  }

  .announce-box {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;

    .logo {
      margin-right: 25px;
    }
  }
  .tag {
    display: inline-block;
    margin-right: 10px;

    &:last-child {
      margin-right:  0;
    }

    a {
      font-weight: 400 !important;
      font-size: 18px;
    }
  }
}

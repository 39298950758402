@mixin media-query($media) {
  @if $media == screen1360 {
    @media only screen and (max-width: 1439px)  { @content; }
  }
  @else if $media == screen1300 {
    @media only screen and (max-width: 1270px)  { @content; }
  }
  @else if $media == screen1024 {
    @media only screen and (max-width: 1023px)  { @content; }
  }
  @else if $media == screen600 {
    @media only screen and (max-width: 600px)  { @content; }
  }
  @else if $media == screen800 {
    @media only screen and (max-width: 800px)  { @content; }
  }
  @else if $media == mobile360 {
    @media only screen and (max-width: 360px)  { @content; }
  }
  @else if $media == mobile400 {
    @media only screen and (max-width: 400px)  { @content; }
  }
  @else if $media == mobile640 {
    @media only screen and (max-width: 736px)  { @content; }
  }
}

.edit-plan {
  .edit {
    position: relative;
  }
  .edit-options {
    position: absolute;
    right: 0;
    top: 0;

    a {
      margin-right: 15px;
    }
  }
  .edit-title {
    @include clearfix;
  }
  .edit-title input {
    max-width: 60%;
    float: left;
  }
  .edit-buttons {
    text-align: right;
    padding-top: 3px;
    margin-bottom: 15px;

    button {
      display: inline-block;
    }
  }
  .comp-wrap-edit {
    display: none;
    margin-bottom: 50px;
  }

  .save-buttons {
    margin-bottom: 30px;

    button {
      max-width: 195px;
      padding: 13px;
      margin: 0;
      display: inline-block;
      margin-right: 30px;
    }
  }
}

  .main-content {
    .edit-plan {
      border-bottom: none;

      .company-detail {
        border-bottom: none;
      }
    }
  }

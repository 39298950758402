.top-nav {
  justify-content: space-between;
  -webkit-justify-content: space-between;
  align-items: center;
  -webkit-align-items: center;
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  -ms-flex-pack: justify;
  padding: 35px 0;

  .logo {
    img {
      width: 179px;
    }
  }


  .menu {
    list-style: none;
    margin: 0;
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;

    padding: 0;

    li {
      display: inline-block;

      a {
        display: flex;
        display: -webkit-flex;
        display: -ms-flexbox;
        align-items: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        padding: 15px;
        font-size: 14px;
        color: #576272;

        span {
          margin-right: 8px;
          position: relative;
        }

        &:hover {
          color: $blue;
          text-decoration: none;

          span {
            background-position: 0 -29px;
          }
        }
      }
    }
  }

  .commercial {
    box-sizing: border-box;
    padding: 0 15px;
  }

  .commercial img {
    display: block;
    max-width: 100%;
  }
}

nav {
  background-color: $grey;

  .nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
    background-color: transparent;
    border: none;
  }

  .navbar-collapse {
    padding: 0;
  }

  .mobile {
    display: none;
  }

  .navbar {
    margin: 0;
    .navbar-header {
      display: none;
    }
  }

  .main-menu {
    padding: 0;
    list-style: none;
    float: none;
    margin: 0;
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    display: -ms-flexbox;
    -ms-flex-pack: justify;


    .menu-link {
      font-size: 16px;
      text-decoration: none;
      color: #fff;
      display: block;
      padding: 30px 0;
      position: relative;

      &.home-icon {
        background-color: transparent;
        border: none;
        padding: 23px 0;

        &:before {
          display: none;
        }
      }

      &:focus,
      &:active,
      &:visited,
      &:hover {
        background-color: transparent;
        border: none;
      }

      &:before {
        content: "";
        background-image: url("../images/icons/menu-arrow.svg");
        display: inline-block;
        width: 14px;
        transform: rotate(-90deg);
        transition: all 0.3s ease;
        position: relative;
        top: -1px;
        height: 7px;
        vertical-align: middle;
        margin-right: 10px;
      }

      span {
        font-weight: 300;
      }
    }

    .dropdown.open {
      .menu-link:before {
        transform: rotate(0deg);
      }
    }
  }

  //dropdown menu

  .dropdown-menu {
    left: 50%;
    top: 82px;
    background-color: #404550;
    border-radius: 0;
    padding: 0;
    margin: 0;
    border: none;
    right: auto;
    border-top: 2px solid #00add9;
    transform: translate(-50%, 0);

    li {
      display: list-item;
    }

    a {
      color: #fff;
      text-decoration: none;
      padding: 10px 15px;
      display: flex;
      display: -webkit-flex;
      justify-content: space-between;
      -webkit-justify-content: space-between;

      span {
        margin-left: 17px;
      }

      &:hover {
        background-color: #00add9;
        color: #fff;
      }
    }
  }
}

//top image

.header-image {
  background-image: url("../images/header-image.jpg");
  background-position: 50% 70%;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;

  .text-holder {
    width: 900px;
    height: 330px;
    margin: 0 auto;
    background-color: rgba(0,173,217,.5);
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    align-items: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
  }

  h2 {
    margin: 0;
    color: #fff;
    text-shadow: 1px 3px 2px rgba(150, 150, 150, 1);
    font-size: 32px;
    max-width: 900px;
  }
}
